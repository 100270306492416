<template>
    <div>
        <Nav></Nav>
    </div>
</template>

<script>
import Nav from '@/components/Navigation.vue'
export default {
    "name":'projectIntroduction',
    components: {
        Nav,
    },
}
</script>

<style lang="scss" scoped></style>