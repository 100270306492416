<template>
    <div class="serve">
        <Nav :routerValue="routerValue"></Nav>
        <div class="contactUs wow animate__fadeInDown">
            <div class="topimg ">
                <img src="../../assets/img/serve.png" alt="">
            </div>
        </div>
        <!-- 版心内容区域 -->
        <div class="inner_serve">
            <div class="headquarters_title wow animate__fadeInDown">
                <img src="../../assets/img/minilogo.png" alt="">
                <div>企业孵化器的发展历史</div>
            </div>
            <div class="history">
                <div class="history_left wow animate__backInLeft"  data-wow-delay='0.3s'>
                    <img src="../../assets/img/serveLift1.png" alt="">
                </div>
                <div class="history_right wow animate__fadeInDown">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="起源 " name="first">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                1959年，美国有一位叫约瑟夫·曼库索的商人，经营着一栋85万平方英尺的大楼，后来他决定把这个大楼分隔成小单元，分别租给不同的小企业，并且向这些小企业提供融资、咨询等服务来促进它们成长。<br>
                                后来，曼库索从大楼内一家养鸡企业活蹦乱跳的小鸡中获得了灵感，于是把这种经营模式命名为“企业孵化器”。<br>
                                这是世界上第一个孵化器，后来这种模式逐渐从美国扩展到世界各地，企业孵化器的概念也逐渐深入人心。入驻孵化器的创新企业就像是一窝鸡蛋，母鸡就是孵化器公司，这个窝就是孵化场所和提供的一切配套服务。最后成功孵化出来的小鸡，就是顺利“毕业”的企业。
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label=" 发展" name="second">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                1987年，武汉东湖诞生了中国第一个企业解化器。经过30多年的发展,中国的孵化器数量已达世界第一。近几年，随着“大众创业,万众创新”的浪潮,结合国家大力扶持创业孵化政策的不断出台，解化器市场通渐进入繁荣期。<br>
                                行业发展中，大多数的企业解化器，只给初创团队提供相对低廉的办公场所，营收也主要是依赖空间租金和政府补贴。随着政策倾斜与市场发展，也出现了很多企业孵化器不再局限于场所出租业务，开始为初创团队提供一些企业服务,比如公司注册、财务代理政策申报等，同时会为初创团队提供专业导师的辅导咨询支持。
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="headquarters_title wow animate__fadeInDown">
                <img src="../../assets/img/minilogo.png" alt="">
                <div>企业孵化器的模式断代史</div>
            </div>
            <div class="history direction">
                <div class="history_left wow animate__backInRight" data-wow-delay='0.3s'>
                    <img src="../../assets/img/serveRight1.png" alt="">
                </div>
                <div class="history_right wow animate__fadeInDown">
                    <el-tabs v-model="activeName2">
                        <el-tab-pane label="V1.0 场地服务" name="first">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                V1.0版本的孵化器，就像是办公届的“群租房”，只给初创团队提供给相对低廉的创业场所，孵化器公司的营收主要是依赖空间租金和政府补贴。
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="V2.0 企业服务" name="second">
                            <div style="color: #7e7e7e;line-height: 30px;">
                               V2.0版本的企业解化器除了出租办公场地之外，还可以为初创企业提供一些基本的企业服务,例如:公司注册、财务代理、政策申报、科技成果、导师辅导、资本对接等等。对于资金与经验缺乏的初创企业来说,能一定程度上减少初创项目的创业成本、加快创业速度。
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="V3.0 智慧服务" name="third">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                V3.0版本的企业解化器除了具备10和20两个版本的基本功能外，还将通过为初创企业提供强产业增值服务助力发展。比如:持股孵化、建设公共研发平台等等,与小企业、创业者一同成长。进入这个版本的躯化器提供的服务是多维度的，不仅仅是单纯的资金还可能是金融赋能、产业互联、知识信息等各种专业服务。
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="headquarters_title">
                <img src="../../assets/img/minilogo.png" alt="">
                <div>医药医疗产业智慧孵化器</div>
            </div>
            <div class="history">
                <div class="history_left wow animate__backInLeft" data-wow-delay='0.3s'>
                    <img src="../../assets/img/fuhuaqi1.png" alt="">
                </div>
                <div class="history_right wow animate__backInRight" data-wow-delay='0.3s'>
                    <el-tabs v-model="activeName3">
                        <el-tab-pane label="新兴弱小代表未来" name="first">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                创新的小企业代表着未来,企业解化器当下所服务的100家小企业，未来可能会有10家成为了不起的大企业,这就是企业孵化器的使命。<br>
                                通过与优秀的，有发展前景的小企业产生强关系，为小企业创造更多价值，在未来获得相关收益,是高质量企业孵化器的商业模型,也是未来企业孵化器的发展方向。
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="V3.5医药医疗产业智慧孵化器" name="second">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                良医集团聚焦医药医疗产业,汲取不同类型企业孵化器模式精髓,博采众长，开创医药医疗产业智慧孵化器模式。采用持股孵化,产业创业资源紧密型赋能小企业,与创业者一起成长。提供增值服务，让初创企业专注于产品和业务运营。最终通过为小企业创造价值而获得相关收益。
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="良医“六大”孵化体系" name="third">
                            <div style="color: #7e7e7e;line-height: 30px;">
                                良医医药医疗产业智慧孵化模式,持股孵化陪伴初创企业一同成长。以产业变迁为纲，产业链资源共享为核，从初创企业根本需求出发,构建六大服务体系(市场营销体系复活、效率管理体系、财税筹划体系、资证运营体系、咨询投资体系、技术研发体系)，为初创企业的团队建设、数控管理、财税统筹、共享研发、风险资金、供应链金融、客户拓展、公共事务管理、媒介推广、项目控制等多个需求维度全面赋能，让初创企业专注于产品和业务，快速完成0到100的成长
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="headquarters_title end wow animate__fadeInDown">
                <img src="../../assets/img/minilogo.png" alt="">
                <div>已服务客户</div>
            </div>
            <div class="partner">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper1.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper2.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper3.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper4.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper5.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper6.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper7.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper8.png" alt="">
                        </div>
                        <div class="swiper-slide swiper-no-swiping">
                            <img src="../../assets/img/swiper9.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <el-backtop :bottom="450" :visibility-height="1900">
                    <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
                        UP
                    </div>
                </el-backtop>
            </div>
        </div>
        <btm></btm>
    </div>
</template>

<script>
import Nav from '@/components/Navigation.vue'
import btm from '@/components/bottom.vue'
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Autoplay, Navigation, Pagination]);
// import { WOW } from 'wowjs'
export default {
    name: 'enterpriseService',
    components: {
        Nav,
        btm,
    },
    data() {
        return {
            activeName: 'first',
            activeName2: 'first',
            activeName3: 'first',
            routerValue:'enterpriseService'
        }
    },

    methods: {
        initSwiper() {
            var swiper = new Swiper('.swiper-container', {
                speed: 4000,
                slidesPerView: 5,
                spaceBetween: 30,
                loop: true,
                loopedSlides: 4,
                loopAdditionalSlides: 2,
                allowTouchMove: false,
                autoplay: {
                    delay: 1,
                    // stopOnLastSlide: false,
                    disableOnInteraction: false,
                }
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initSwiper();
            // new WOW({ live: true }).init();
        })
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
    font-size: 16px;
    font-weight: 600;
}

.serve {
    width: 100%;

    .contactUs {
        width: 100%;
        background: #ffffff;
        .topimg{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }

    .inner_serve {
        width: 1500px;
        margin: 0 auto;

        .headquarters_title {
            margin: 100px 0 40px;
            display: flex;
            align-items: center;

            :last-child {
                margin-left: 8px;
                font-size: 30px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
            }
        }

        .history {
            width: 100%;
            height: 410px;
            background: #F7FAFF;
            border-radius: 0px 10px 10px 0px;
            display: flex;

            .history_left {
                width: 45%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .history_right {
                padding: 3%;
                width: 49%;
            }
        }

        .direction {
            flex-direction: row-reverse
        }
        .end{
            margin-bottom: 0;
        }
        .partner {
        width: 100%;
        height: 270px;
        padding-top: 80px;
        background: url("../../assets/img/partnerBg.png") no-repeat top;
        overflow: hidden;
      }
      
      .swiper-wrapper {
        /* 这里是改变其动画效果为匀速形式，不加此样式的话会造成滚动卡顿，看起来效果不平滑 */
        /* 样式做了各种浏览器的兼容 */
        transition-timing-function: linear !important;
        -webkit-transition-timing-function: linear !important;
        -moz-transition-timing-function: linear !important;
        -ms-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
      }

      .swiper-slide {
        width: 170px;
        height: 86px;
        // background: #FFFFFF;
        border: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
}
</style>