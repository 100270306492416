<template>
    <div id="app">
        <EchartMap :mapData="mapData" style="width: 100%;height: 100vh" :mapOption="{}"></EchartMap>
    </div>
</template>
<script>
import EchartMap from '@/components/EchartMap'
export default {
    components: {
        EchartMap
    },
    data() {
        return {
            mapData: [{
                "flight_ids": "11,18,24",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "哈尔滨",
                "to_longitude": "126.522207",
                "to_latitude": "45.80162",
                "num": 483,
                "office": "黑龙江办事处",
                "userName": "刘旺阳",
                "phone": "15596630496"
            },
            {
                "flight_ids": "32,41,53,60,68,77,81",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "郑州",
                "to_longitude": "113.644099",
                "to_latitude": "34.76916",
                "num": 237,
                "office": "河南办事处",
                "userName": "刘天宇",
                "phone": "18681837627"
            },
            {
                "flight_ids": "33,40,48,52,61,69,76,80",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "天津",
                "to_longitude": "117.286764",
                "to_latitude": "39.0013",
                "num": 395,
                "office": "天津办事处",
                "userName": "朱怡凡",
                "phone": "18802952446"
            },
            {
                "flight_ids": "34,50,54,62,70,78,82,90,98",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "重庆",
                "to_longitude": "106.553263",
                "to_latitude": "29.55668",
                "num": 717,
                "office": "重庆办事处",
                "userName": "张耿",
                "phone": "18706717731"
            },
            {
                "flight_ids": "35,43,55,63,71,79,83,91",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "福州",
                "to_longitude": "119.292069",
                "to_latitude": "26.14414",
                "num": 1125,
                "office": "福建办事处",
                "userName": "惠少杰",
                "phone": "13299015263"
            },
            {
                "flight_ids": "36,44,59,64,72,87",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "南京",
                "to_longitude": "118.832137",
                "to_latitude": "32.03832",
                "num": 733,
                "office": "江苏办事处",
                "userName": "李泓诺",
                "phone": "17749549219"
            },
            {
                "flight_ids": "37,57,67,74,85",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "石家庄",
                "to_longitude": "114.508772",
                "to_latitude": "38.08378",
                "num": 312,
                "office": "河北办事处",
                "userName": "李泓诺",
                "phone": "17749549219"
            },
            {
                "flight_ids": "38,47,58,65,100",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "长春",
                "to_longitude": "125.326383",
                "to_latitude": "43.79777",
                "num": 603,
                "office": "吉林办事处",
                "userName": "李璐璠",
                "phone": "17691359889"
            },
            {
                "flight_ids": "39,45,56,66,73,86",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "济南",
                "to_longitude": "117.194778",
                "to_latitude": "36.65215",
                "num": 589,
                "office": "山东办事处",
                "userName": "李洋",
                "phone": "15054162170"
            },
            {
                "flight_ids": "4,10,16,22",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "北京",
                "to_longitude": "116.403694",
                "to_latitude": "39.94946",
                "num": 398,
                "office": "北京办事处",
                "userName": "梁素苓",
                "phone": "18311165728"
            },
            {
                "flight_ids": "5,17,23",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "沈阳",
                "to_longitude": "123.486653",
                "to_latitude": "41.68252",
                "num": 75,
                "office": "辽宁办事处",
                "userName": "崔林涛",
                "phone": "15029189260"
            },
            {
                "flight_ids": "7,12,25",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "上海",
                "to_longitude": "121.518142",
                "to_latitude": "31.21185",
                "num": 408,
                "office": "上海办事处",
                "userName": "张鸣岐",
                "phone": "15596623876"
            },
            {
                "flight_ids": "8,13,20,26",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "海口",
                "to_longitude": "110.350983",
                "to_latitude": "19.96804",
                "num": 162,
                "office": "海南办事处",
                "userName": "胡博",
                "phone": "15809201271"
            },
            {
                "flight_ids": "9,14,21,27",
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "广州",
                "to_longitude": "113.239359",
                "to_latitude": "23.18555",
                "num": 124,
                "office": "广东办事处",
                "userName": "王犇",
                "phone": "13027029997"
            },
            {
                "flight_ids": 28,
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "武汉",
                "to_longitude": "114.361594",
                "to_latitude": "30.60108",
                "num": 10,
                "office": "湖北办事处",
                "userName": "文一行",
                "phone": "15353831187"
            },
            {
                "flight_ids": 29,
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "郑州",
                "to_longitude": "113.644099",
                "to_latitude": "34.76916",
                "num": 40,
                "office": "河南办事处",
                "userName": "吴继聪",
                "phone": "15129392739"
            },
            {
                "flight_ids": 31,
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "成都",
                "to_longitude": "104.132697",
                "to_latitude": "30.56128",
                "num": 123,
                "office": "四川办事处",
                "userName": "郭轩",
                "phone": "15769209364"
            },
            {
                "flight_ids": 92,
                "from": "西安",
                "from_longitude": "108.56",
                "from_latitude": "34.15",
                "to": "杭州",
                "to_longitude": "120.211934",
                "to_latitude": "30.27427",
                "num": 13,
                "office": "浙江办事处",
                "userName": "黄欣培",
                "phone": "18891992186"
            },
            {
                "flight_ids": '912122',
                // "from": "西安",
                // "from_longitude": "108.56",
                // "from_latitude": "34.15",
                "to": "西安",
                "to_longitude": "108.56",
                "to_latitude": "34.15",
                "num": 112133,
                // "office": "西安总部",
                // "userName": "良医集团",
                // "phone": "4001861200"
            },
            ]
        }
    }
}

</script>
<style>
* {
    margin: 0;
    padding: 0;
}
</style>
