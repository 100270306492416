<template>
  <div class="contact">
    <Nav :routerValue="routerValue"></Nav>
    <div class="contactUs wow animate__fadeInDown">
      <div class="topimg ">
        <img src="../../assets/img/concact.png" alt="">
      </div>
      <div class="home">
        <i class="el-icon-s-home"></i>
        <div>首页</div>
         <i class="el-icon-arrow-right"></i>
         <div>联系我们</div>
      </div>
    </div>
    <div class="headquarters">
      <div class="headquarters_title wow animate__fadeInDown">
        <img src="../../assets/img/minilogo.png" alt="">
        <div>西安总部</div>
      </div>
      <div class="headquarters_box wow animate__fadeInDown">
          <div class="boxImg">
            <img src="../../assets/img/zongbu1.png" alt="">
          </div>
          <div class="info">
              <div class="top">
                <img src="../../assets/img/qiye.png" alt="">
                <div>西安良医信息技术有限公司</div>
              </div>
              <div class="line"></div>
              <div class="text">
                <img src="../../assets/img/dizhi .png" alt="">
                <div>地址：陕西省西安市未央区凤城十二路富尔顿大厦A座13层</div>
              </div>
              <div class="text">
                <img src="../../assets/img/dianhua.png" alt="">
                <div>电话：400-186-1200</div>
              </div>
              <div class="text">
                <img src="../../assets/img/youjian.png" alt="">
                <div>邮箱：liangfang@163.com</div>
              </div>
              <div class="textimg">
                <div></div>
                <div class="jump">
                  <a href="https://www.amap.com/search?query=%E9%99%95%E8%A5%BF%E7%9C%81%E8%A5%BF%E5%AE%89%E5%B8%82%E6%9C%AA%E5%A4%AE%E5%8C%BA%E5%87%A4%E5%9F%8E%E5%8D%81%E4%BA%8C%E8%B7%AF%E5%AF%8C%E5%B0%94%E9%A1%BF%E5%A4%A7%E5%8E%A6A%E5%BA%A713%E5%B1%82&city=610000&geoobj=107.980596%7C33.752478%7C110.386124%7C34.81114&zoom=9.72" target="_blank">
                  <img src="../../assets/img/ditu.png" alt="">
                  <div>跳转地图</div>
                  </a>
                </div>
              </div>
          </div>
    </div>
      <div class="headquarters_title wow animate__fadeInDown">
        <img src="../../assets/img/minilogo.png" alt="">
        <div>运营中心</div>
      </div>
      <div class="operation wow animate__fadeInDown">
        <div class="operation_left">
          <div class="info ">
              <div class="top">
                <img src="../../assets/img/qiye.png" alt="">
                <div>杭州运营中心</div>
              </div>
              <div class="line"></div>
              <div class="text">
                <img src="../../assets/img/dizhi .png" alt="">
                <div>地址：滨江区信成路857号悦江商业中心3401室</div>
              </div>
              <div class="text">
                <img src="../../assets/img/dianhua.png" alt="">
                <div>电话：400-186-1200</div>
              </div>
              <div class="textimg">
                <div></div>
                <div class="jump">
                  <a href="https://www.amap.com/search?query=%E6%BB%A8%E6%B1%9F%E5%8C%BA%E4%BF%A1%E6%88%90%E8%B7%AF857%E5%8F%B7%E6%82%A6%E6%B1%9F%E5%95%86%E4%B8%9A%E4%B8%AD%E5%BF%833401%E5%AE%A4&city=610100&geoobj=108.189661%7C33.849723%7C110.139737%7C34.70798&zoom=10.02" target="_blank">
                  <img src="../../assets/img/ditu.png" alt="">
                  <div>跳转地图</div>
                  </a>
                </div>
              </div>
          </div>
        </div>
        <div class="operation_right operation_left">
          <div class="info ">
              <div class="top">
                <img src="../../assets/img/qiye.png" alt="">
                <div>广州运营中心</div>
              </div>
              <div class="line"></div>
              <div class="text">
                <img src="../../assets/img/dizhi .png" alt="">
                <div>地址：白云区嘉禾望岗上胜创意园S栋501室</div>
              </div>
              <div class="text">
                <img src="../../assets/img/dianhua.png" alt="">
                <div>电话：400-186-1200</div>
              </div>
              <div class="textimg">
                <div></div>
                <div class="jump">
                  <a href="https://www.amap.com/search?query=%E7%99%BD%E4%BA%91%E5%8C%BA%E5%98%89%E7%A6%BE%E6%9C%9B%E5%B2%97%E4%B8%8A%E8%83%9C%E5%88%9B%E6%84%8F%E5%9B%ADS%E6%A0%8B501%E5%AE%A4&city=440100&geoobj=113.277946%7C23.229951%7C113.28887%7C23.235298&zoom=17.5" target="_blank">
                  <img src="../../assets/img/ditu.png" alt="">
                  <div>跳转地图</div>
                  </a>
                </div>
              </div>
          </div>
      </div>
      </div>
    </div>
    <div class="map">
      <div class="map_inner wow animate__fadeInDown">
        <div class="headquarters_title">
        <img src="../../assets/img/minilogo.png" alt="">
        <div>全国办事处/子公司</div>
      </div >
        <Map></Map>
      </div>
      <div>
          <el-backtop :bottom="450" :visibility-height="2000">
            <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
              UP
            </div>
          </el-backtop>
        </div>
    </div>
    <btm></btm>
  </div>
</template>
<script>
import Nav from '@/components/Navigation.vue'
import btm from '@/components/bottom.vue'
import Map from '@/components/map.vue'
import { WOW } from 'wowjs'
export default {
  name: 'contact',
  components: {
    Nav,
    btm,
    Map
  },
  data() {
      
        return {
          routerValue:'contact'
    }
    
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor='#EEF2F6'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor=''
    next()
  },
    created() {
        
    },
    methods: {
        
  },
  mounted() {
    this.$nextTick(() => {
      new WOW({ live: true }).init();
    })
    },
}
</script>

<style lang="scss" scoped>
  .contact{
    width: 100%;
    .contactUs{
      width: 100%;
      background: #ffffff;
      .topimg{
        width: 100%;
        img{
          width: 100%;
        }
      }
      .home{
        padding: 34px 0;
        width: 1500px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        color: #1A1A1A;
        font-size: 14px;
        font-family: Source Han Sans CN;
        :last-child{
          color: #13A3F0;
        }
      }
    }
    .headquarters{
      width: 1500px;
      margin: 0 auto;
      .headquarters_title{
        padding: 50px 0 43px 0;
        display: flex;
        align-items: center;
        :last-child{
          margin-left: 8px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
      }
      .headquarters_box{
        width: 100%;
        height: 410px;
        display: flex;
        align-items: center;
        margin-bottom: 100px;
        .boxImg{
          width: 46%;
          height: 409px;
        }
         .info{
          padding: 60px 30px 0;
          width: 56%;
          height: 350px;
          background: #ffffff;
          border-radius: 0px 10px 10px 0px;
          .top{
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
            :first-child{
              width: 28px;
              height: 24px;
              margin-right: 10px;
            }
          }
          .line{
            margin-top: 30px;
            width: 100%;
            height: 2px;
            background:#EEF2F6;
          }
          .text{
            margin-top: 33px;
            display: flex;
            align-items: center;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #808080;
            line-height: 22px;
            :last-child{
              margin-left: 10px;
            }
          }
          .textimg{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .jump{
              a{
                text-decoration: none;
                :last-child{
                font-size: 14px;
                font-family: Source Han Sans CN;
                color: #13A3F0;
              }
              }
            }
          }
         }
      }
    }
    .operation{
      width: 100%;
      height:300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 100px;
      .operation_left{
        width: 47%;
        height: 300px;
        background: #ffffff;
        border-radius: 10px;
        .info{
          padding: 30px 30px 0;
          .top{
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
            :first-child{
              width: 28px;
              height: 24px;
              margin-right: 10px;
            }
          }
          .line{
            margin-top: 30px;
            width: 100%;
            height: 2px;
            background:#EEF2F6;
          }
          .text{
            margin-top: 33px;
            display: flex;
            align-items: center;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #808080;
            line-height: 22px;
            :last-child{
              margin-left: 10px;
            }
          }
          .textimg{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .jump{
              a{
                text-decoration: none;
                :last-child{
                font-size: 14px;
                font-family: Source Han Sans CN;
                color: #13A3F0;
              }
              }
            }
          }
         }
      }
     
    }
    .map{
      overflow: hidden;
      width: 100%;
      // height: 1100px;
      background-color: #ffffff;
      .map_inner{
        width: 1500px;
        margin: 0 auto;
        margin-top: 100px;
        height: 100%;
        .headquarters_title{
        display: flex;
        align-items: center;
        :last-child{
          margin-left: 8px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
      }
      }
    }
  }
</style>