<template>
    <div class="about">
        <Nav :routerValue="routerValue"></Nav>
        <div class="contactUs wow animate__fadeInDown">
            <div class="topimg ">
                <div class="text">
                    <div> 关于良医</div>
                    <div> ABOUT DOCTOR</div>
                </div>
            </div>
        </div>
        <div class="introduce">
            <div class="box  wow animate__fadeInDown">
                <div class="introduce_top">
                    <div class="introduce_left ">
                        <img src="../../assets/img/minilogo.png" alt="">
                        <div class="serve">集团简介 </div>
                        <div>GROUP PROFILE</div>
                    </div>
                    <div class="introduce_right">
                        <img src="../../assets/img/title1.png" alt="">
                    </div>
                </div>
                <div class="introduction">
                    良医集团（简称良医）成立于2014年12月1日，是一家专为医药及泛医药领域初创项目提供“智慧孵化”服务的集团型公司。<br>
                    良医在中国医疗改革不断深入与移动互联网经济快速崛起交织的时代背景下“让就医不再难”作为企业使命，以建设医药创新项目成长摇篮、共创医药数字化营销公网为愿景，坚持机制创新，集聚一批擅长产学研协同的专业人才、掌握前沿技术的创业者、面向科技创新的投资
                    人，致力建设服务医药领域初创项目的智慧孵化平台。<br>
                    良医以“生态布局、聚合发展、数控提效、价值共赢“为发展观：即以客户为中心，以商机吸纳生态资源，实现一站应用；以融资金、融资源、融人才的合伙人机制，聚合成规模优势；以团队效率管理为抓手，量化目标与价值分配融合资源；从客户到合作伙伴到团队转化，形成价值共赢体系，达成知行合一，最终践行“让就医不再难”的使命。
                </div>
            </div>
        </div>
        <div class="inner">
            <div class="culture  wow animate__fadeInDown">
                <div class="introduce_top">
                    <div class="introduce_left ">
                        <img src="../../assets/img/minilogo.png" alt="">
                        <div class="serve">企业文化 </div>
                        <div>company culture</div>
                    </div>
                </div>
                <div class="list">
                    <div class="item">
                        <div><img src="../../assets/img/kehu.png" alt=""></div>
                        <div class="text">
                            <div>以真立业 客户至上</div>
                            <div>始终坚持诚信为本的立业原则，始终保证客户利益为第一优先。</div>
                        </div>
                    </div>
                    <div class="item">
                        <div><img src="../../assets/img/linian.png" alt=""></div>
                        <div class="text">
                            <div>精、细、全服务理念</div>
                            <div>精益求精 将服务做到极致，严格执行标准 管理细化 执行细化，在不同阶段 为客户提供全方位服务。</div>
                        </div>
                    </div>
                    <div class="item">
                        <div><img src="../../assets/img/fazhan.png" alt=""></div>
                        <div class="text">
                            <div>用创新 赢发展</div>
                            <div>创新是企业的第一生产力，唯有不断创新 才能不断发展。</div>
                        </div>
                    </div>
                    <div class="item">
                        <div><img src="../../assets/img/xietong.png" alt=""></div>
                        <div class="text">
                            <div>以人为本 机制协同</div>
                            <div>精益求精 将服务做到极致，严格执行标准 管理细化 执行细化，在不同阶段 为客户提供全方位服务。</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="history  wow animate__fadeInDown">
            <div class="path">
                <div class="introduce_top">
                    <div class="introduce_left ">
                        <img src="../../assets/img/minilogo.png" alt="">
                        <div class="serve">发展历程 </div>
                        <div>development path</div>
                    </div>
                </div>
                <div>
                    <year>
                    </year>
                </div>
            </div>
        </div>
        <div class="leader  wow animate__fadeInDown">
            <div class="introduce_top">
                <div class="introduce_left ">
                    <img src="../../assets/img/minilogo.png" alt="">
                    <div class="serve">领导团队 </div>
                    <div>leadership team</div>
                </div>
            </div>
            <div class="team">
                <div class="img_box">
                    <div class="boss">
                        <img src="../../assets/img/boss.jpg" alt="">
                    </div>
                    <div class="memoir">
                        <div>常崇旺 博士</div>
                        <div style="font-weight: bold;font-size: 15px">良医集团首席执行官(CEO) · 中国第四军医大学神经外科学专业硕士、博士 · 国家科技部轻转诊网络工程e转诊课题组负责人 · 医药行业战略分析专家 · 米墨MIMO平台创始人 · 和君医改政策研究专家医药数字化营销专家</div>
                        <div style="line-height: 32px;">
                          深耕医疗医药行业多年，拥有互联网大数据健康服务理念，并对医药工商业营销管理、数字化产业变革和医疗体制改革有深入研究，是“e转诊”与“米墨 MIMO”平台创始人。其设计开发的“米墨MIMO”平台可为医药行业提供完善的数字化营销升级服务。是医药企业转型升级实操战略的领导者，同时担任和君咨询、哈佛商学院(中国网校)首席顾问专家，近年来已为国内百余家医药企业提供数字化营销升级战略规划及营销整合方案。
                            <!--良医集团首席执行官(CEO)、良医医药医疗产业智慧孵化器创始人、中国第四军医大学神经外科学专业硕士、博士、医药行业战略分析专家、和君医改政策研究专家、医药数字化营销专家、(日本)平安津村中国市场总顾问深耕医疗医药行业 15 年，拥有互联网大数据健康服务理念，对医药工商业营销管理数字化产业变革和医疗体制改革有着深入研究。依托丰富的医疗医药行业资源，为客户提供“咨询+数字化”专业的咨询服务及整体定制化解决方案帮助医疗医药行业数字化转型，是医药企业转型升级实操战略的领导者，近年来已为国内百余家医药企业提供数字化营销升级战略规划及营销整合方案-->
                        </div>
                    </div>
                </div>
                <!-- <div class="cadre">
                    <div class="swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide ">
                                <img src="../../assets/img/boss.jpg" alt="">
                                <div class="img">总经理：常崇旺</div>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/img/boss.jpg" alt="">
                                <div class="img">总经理：常崇旺</div>
                            </div>
                            <div class="swiper-slide ">
                                <img src="../../assets/img/boss.jpg" alt="3">
                                <div class="img">总经理：常崇旺</div>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/img/boss.jpg" alt="4">
                                <div class="img">总经理：常崇旺</div>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/img/boss.jpg" alt="5">
                                <div class="img">总经理：常崇旺</div>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/img/boss.jpg" alt="6">
                                <div class="img">总经理：常崇旺</div>
                            </div>
                        </div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div> -->
                <!-- </div> -->
            </div>
        </div>
        <div>
            <el-backtop :bottom="450" :visibility-height="2000">
                <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
                    UP
                </div>
            </el-backtop>
        </div>
        <btm></btm>
    </div>
</template>

<script>
import btm from '@/components/bottom.vue'
import Nav from '@/components/Navigation.vue'
import year from '@/components/yearPath.vue'
export default {
    components: {
        Nav,
        year,
        btm,
    },
    data() {
        return {
            routerValue: 'aboutLiangYi',
        }
    },
    mounted() {
        // this.initSwiper();

    },
    methods: {
        // initSwiper() {
        //     var mySwiper = new Swiper('.swiper', {
        //         effect: 'coverflow',
        //         slidesPerView: 5,
        //         centeredSlides: true,
        //         loopAdditionalSlides: 2,
        //         coverflowEffect: {
        //             rotate: 30,
        //             stretch: 10,
        //             depth: 60,
        //             modifier: 2,
        //             slideShadows: true
        //         },

        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         },

        //     });
        //     mySwiper.slideToLoop(2, 100, false);
        // }
    }
}
</script>

<style lang="scss" scoped>
.about {
    width: 100%;

    .contactUs {
        .topimg {
            width: 100%;
            height: 430px;
            background: url('~@/assets/img/about.png') no-repeat center;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                width: 200px;
                text-align: center;

                :first-child {
                    font-size: 42px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 35px;
                    letter-spacing: 7px;
                }

                :last-child {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 55px;
                }
            }
        }
    }

    .introduce {
        width: 100%;
        height: 400px;
        background: #F7FAFF;
        overflow: hidden;

        .box {
            width: 1500px;
            margin: 0 auto;
            height: 100%;

            .introduce_top {
                display: flex;
                justify-content: space-between;
                margin-top: 60px;

                .introduce_left {
                    display: flex;
                    align-items: flex-end;

                    .serve {
                        margin-left: 10px;
                        font-size: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #333333;
                    }

                    :nth-child(3) {
                        margin-left: 10px;
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #13A3F0;
                    }
                }
            }

            .introduction {
                margin-top: 40px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #808080;
                line-height: 30px;
            }

        }
    }

    .inner {
        width: 1500px;
        margin: 0 auto;

        .culture {
            width: 100%;
            height: 400px;

            .introduce_top {
                margin-top: 100px;

                .introduce_left {
                    display: flex;
                    align-items: flex-end;

                    .serve {
                        margin-left: 10px;
                        font-size: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #333333;
                    }

                    :nth-child(3) {
                        margin-left: 10px;
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #13A3F0;
                        text-transform: uppercase;
                    }
                }
            }

            .list {
                padding: 50px 0;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .item {
                    margin-bottom: 50px;
                    width: 50%;
                    display: flex;
                    align-items: center;

                    .text {
                        margin-left: 20px;

                        :first-child {
                            font-size: 24px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #13A3F0;
                        }

                        :last-child {
                            margin-top: 10px;
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #808080;
                        }
                    }
                }
            }
        }
    }

    .history {
        width: 100%;
        height: 700px;
        background: url('~@/assets/img/pathUrl.png') no-repeat center;
        background-size: 100% 800px;

        .path {
            width: 1500px;
            margin: 0 auto;

            .introduce_top {
                margin-top: 100px;

                .introduce_left {
                    display: flex;
                    align-items: flex-end;

                    .serve {
                        margin-left: 10px;
                        font-size: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #333333;
                    }

                    :nth-child(3) {
                        margin-left: 10px;
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #13A3F0;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .leader {
        width: 1500px;
        margin: 0 auto;

        .introduce_top {
            margin-top: 100px;

            .introduce_left {
                display: flex;
                align-items: flex-end;

                .serve {
                    margin-left: 10px;
                    font-size: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                }

                :nth-child(3) {
                    margin-left: 10px;
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #13A3F0;
                    text-transform: uppercase;
                }
            }
        }

        .team {
            width: 1400px;
            padding: 50px;

            .img_box {
                margin-bottom: 60px;
                width: 100%;
                height: 230px;
                display: flex;
                // align-items: center;
                justify-content: space-between;

                .boss {
                    width: 165px;
                    overflow: hidden;
                    position: relative;
                    &:hover::after {
                        left: 150%;
                        transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
                        -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -60px;
                        left: -170px;
                        background: white;
                        width: 30px;
                        height: 300px;
                        opacity: 20%;
                        transform: rotate(-45deg);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;




                    }
                }

                .memoir {
                    width: 86%;
                    padding: 10px 0;

                    :nth-child(1) {
                        font-size: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #4D4D4D;
                    }

                    :nth-child(2) {
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #4D4D4D;
                        margin-top: 10px;
                    }

                    :nth-child(3) {
                        margin-top: 20px;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #808080;
                    }
                }


            }

            .cadre {
                width: 100%;
                // overflow: hidden;
                height: 300px;

                .swiper {
                    width: 100%;

                }

                .swiper-slide {
                    width: 165px;
                    height: 230px;
                    margin-right: 82px;
                    img {
                        width: 165px;
                        display: block;
                        height: 200px;
                        border-radius: 5px 5px 0 0;
                    }

                    .img {
                        background-color: #f5f5f5;
                        width: 165px;
                        height: 30px;
                        line-height: 30px;
                        font-weight: 500;
                        color: #4D4D4D;
                        text-align: center;
                        font-size: 14px;
                        border-radius: 5px;
                    }


                }
            }
        }
    }
}</style>
