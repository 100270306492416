<template>
    <div class="year">
        <horizontalTimeline style=" height: 400px;" :positionId="20" :rightComplete="rightComplete"
            :leftComplete="leftComplete" :timelineList="timelineList" @left-loading="left" @right-loading="right">
            <template v-slot:default="slotProps">
                <div class="content">
                    <div class="chat">
                        {{ slotProps.item.content }}
                    </div>
                </div>
            </template>
            <template v-slot:time="slotProps">
                {{ slotProps.item.time }}
            </template>
        </horizontalTimeline>
    </div>
</template>

<script>
import horizontalTimeline from '@needahouse/vue-horizontal-timeline-component'
const data = [
    { id: 60, content: '医转宝正式更名为米墨MIMO', time: '2017' },
    { id: 61, content: '良医与中国和君咨询达成战略合作,共建初创企业咨询服务体系', time: ' ' },
    { id: 62, content: '良医全国首家业务子公司一山西子公司成立', time: ' ' },
    { id: 63, content: '同年完成全国23个省份直属子公司布局，下辖1000余名商务合伙人', time: ' ' },
    { id: 65, content: '米墨MIMO项目估值5亿元人民币，同年正式向全国推广', time: '2018' },
    { id: 66, content: '米墨MIMO项目完成835万元众筹融资', time: ' ' },
    { id: 661, content: '良医与中国财政科学研究院达成战略合作,授权为合作培训单位', time: ' ' },
    { id: 67, content: '“e转诊”升级为“超医助手”，从单一的任务模式升级成为多边平台的商业平台', time: '2019' },
    { id: 68, content: '米墨MIMO行业首创“服务产品化产品交易化,交易合规化，合规赋能化”，实现从核心解决服务交易合规问题', time: ' ' },
    { id: 69, content: '良医获得国家高新技术企业认证', time: ' ' },
    { id: 70, content: '良医与平安银行达成战略合作，共建三方安全支付体系', time: ' ' },
    { id: 71, content: '米墨MIMO20产品正式上线，从根源解决营销服务过程中产生角色的合规问题', time: '2020' },
    { id: 72, content: '米墨MIMO完成全国范围的代征中心布局，可以更高效便捷的为用户提供相关业务支持', time: ' ' },
    { id: 73, content: '平安银行成为米墨MIMO战略合作伙伴，提供现金管理综合服务并设计了专属保险产品', time: ' ' },
    { id: 74, content: '米墨MIMO上线直推模式:“双百计划”，即一百个药品精准面向百万自然人的招商与精细管理推广', time: ' ' },
    { id: 75, content: '良医总部成立六大职能中心，搭建六大孵化服务体系', time: ' ' },
    { id: 34, content: '米墨MIMO提出“借合规创新营销”全新理念为用户提供更深度的营销解决方案', time: '2021' },
    { id: 351, content: '米墨电商项目正式启动孵化', time: ' ' },
    { id: 361, content: '效率管理系统STEC土气云正式启动孵化供应链金融项目正式启动孵化', time: ' ' },
]
export default {
    components: {
        horizontalTimeline
    },
    data() {
        return {
            timelineList: null,
            rightComplete: false,
            leftComplete: false,
        }
    },
    methods: {
        right(resolve) {
            setTimeout(() => {
                this.timelineList = this.timelineList.concat([
                    { id: 35, content: '米墨电商获太极药业“霍香正气液”产品电商终端全国经销总代理', time: '2022' },
                    { id: 36, content: '与日本平安津村有限公司达成战略合作，创始人常崇旺兼任市场顾问', time: ' ' },
                    { id: 37, content: 'STEC士气云完成核心技术困境突破，完成内测', time: ' ' },
                    { id: 38, content: '合规医生集团项目正式启动解化', time: ' ' },
                    { id: 39, content: '药店经营辅助工具小桥项目入驻孵化', time: ' ' },
                    { id: 40, content: '确立并启动实施“全国布网”的战略目标', time: '2023' },
                    { id: 41, content: '医院患者管理系统正式启动孵化', time: ' ' },
                    { id: 42, content: '批文当铺项目正式启动孵化', time: ' ' },
                    { id: 43, content: '鹰瞳科技入驻孵化', time: ' ' },
                    { id: 44, content: '销讯通入驻孵化', time: ' ' },
                    { id: 45, content: '壹鼎科技入驻孵化', time: ' ' },
                    { id: 46, content: '升级为良医集团', time: ' ' },
                ])
                this.rightComplete = true
                resolve()
            }, 1000)
        },
        left(resolve) {
            setTimeout(() => {
                this.timelineList =
                    [
                        { id: 1, content: '良医成立', time: '2014' },
                        { id: 2, content: '中国科技部轻转诊课随项目e转诊正式启动孵化', time: '2015' },
                        { id: 3, content: '医药数字化营销平台“医转宝“项目正式启动孵化', time: '2016' },
                        { id: 52, content: 'e转诊成为当年中国互联网转诊第一平台良医成立传媒、财税、法律、营销四大服务板块', time: ' ' },
                        { id: 53, content: '良医成立传媒、财税、法律、营销四大服务板块', time: ' ' },
                    ].concat(this.timelineList)
                this.leftComplete = true
                resolve()
            }, 1000)
        }
    },
    mounted() {
        setTimeout(() => {
            this.timelineList = data
        }, 500)
    }
}
</script>

<style lang="scss" scoped>
::v-deep .scroll-container.scroll-show{
    overflow-x:hidden !important;
}
::v-deep .timeline .time{
    font-weight: 600 ;
}
.year {
    margin-top: 150px;
    height: 400px;
  ::v-deep .icon-arrow-left {
    display: none;
  }
  ::v-deep  .arrow-right {
    display: none;
  }
}

.content {
    padding: 5px
}

.chat {
    text-align: left;
    position: relative;
    min-width: 230px;
    max-width: 180px;
    padding: 10px;
    word-wrap: break-word;
    // border: 1px solid #e4e7ed;
    line-height: 22px;
    border-radius: 4px;
    // white-space: pre-wrap;
    color: #808080;
}
</style>
