<template>
  <div class="pressCenter">
    <Nav :routerValue="routerValue"></Nav>
    <div class="contactUs wow animate__fadeInDown">
      <div class="topimg ">
        <img src="../../assets/img/newscenter.png" alt="">
      </div>
      <div class="home">
        <i class="el-icon-s-home"></i>
        <div>首页</div>
        <i class="el-icon-arrow-right"></i>
        <div>新闻中心</div>
      </div>
    </div>
    <div class="newList wow animate__fadeInDown">
      <div class="top" v-for="item in list" :key="item.id"  @click="lookDetails(item.id)">
        <div class="newsImg_left">
          <img class="newsImg" :src="item.cover" alt="">
        </div>
        <div class="convention">
          <div class="title">
            <img src="../../assets/img/qiye.png" alt="">
            <div>{{ item.newsColumn }}</div>
          </div>
          <div class="shareholder">{{ item.title }}</div>
          <div class="description">{{ item.seoAbstract }}
          </div>
          <div class="time">
            <div class="date">
              <img src="../../assets/img/riqi.png" alt="">
              <div>{{ item.updateTime }}</div>
            </div>
            <div class="info">
              <div><span class="text">查看详情<i class="el-icon-arrow-right"></i></span> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-backtop :bottom="450" :visibility-height="1000">
        <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
          UP
        </div>
      </el-backtop>
    </div>
    <div class="block" style="text-align: center; margin: 20px 0 40px 0;">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        @prev-click="PreviousPage" @next-click="Nextpage" :current-page="newsList.pageNum" :page-size="newsList.pageSize"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>

    </div>
    <btm></btm>
  </div>
</template>

<script>
import Nav from '@/components/Navigation.vue'
import btm from '@/components/bottom.vue'
import { news } from "@/api/ly"
export default {
  "name": 'pressCenter',
  components: {
    Nav,
    btm
  },
  data() {
    return {
      routerValue: 'pressCenter',
      newsList: {
        pageNum: 1,
        pageSize: 6,
      },
      list: [],
      total: 0
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      news(this.newsList).then(res => {
        if (res.code == 200) {
          this.list = res.rows;
          this.total = res.total
        }
      })
    },
    // 上一页
    PreviousPage(page) {
      this.newsList.pageNum = page;
      this.init();
    },
    // 下一页
    Nextpage(page) {
      this.newsList.pageNum  = page;
      this.init();
    },
    handleSizeChange(size) {
      this.newsList.pageSize  = size;
      this.newsList.pageNum  = 1;
      this.init();
    },
    // 当前页数改变时
    handleCurrentChange(page) {
      this.newsList.pageNum  = page;
      this.init();
    },

    lookDetails(value) {
      this.$router.push({name:'newsDetails', params:{id:value}});
    },
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#EEF2F6'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  },
}
</script>

<style lang="scss" scoped>
.pressCenter {
  width: 100%;

  .contactUs {
    width: 100%;
    background: #ffffff;
    .topimg{
      width: 100%;
      img{
        width: 100%;
      }
    }

    .home {
      padding: 34px 0;
      width: 1500px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      color: #1A1A1A;
      font-size: 14px;
      font-family: Source Han Sans CN;

      :last-child {
        color: #13A3F0;
      }
    }
  }

  .newList {
    padding-top: 50px;
    width: 1500px;
    height: 1130px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-between;

    .top {
      width: 450px;
      height: 490px;
      background-color: #ffffff;
      // margin-bottom: 50px;
      transition: 0.3s;
      margin: 0 50px 50px 0;
      cursor: pointer;

      &:hover {
        transform: translateY(-20px);
        transition: 0.3s;
      }

      .newsImg_left {
        width: 450px;
        height: 270px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .convention {
        padding: 30px;

        .title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          display: flex;
          align-items: center;

          :last-child {
            margin-left: 10px;
          }
        }

        .shareholder {
          width: 100%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin: 5px 0 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap
        }

        .description {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          width: 407px;
          height: 56px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time {
          margin-top: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #808080;

          .date {
            width: 60%;
            display: flex;
            align-items: center;

            :last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>